<template>
  <div class="pt-12 md:mt-32 pb-14 md:pb-24 lg:mt-52">
    <SectionHeadingCta :title="heading.title"
                       :cta="heading.cta"
                       :type="heading.type"
    />
    <Container class="mt-12 lg:mt-24">
      <div class="flex flex-wrap items-center justify-between pt-4 pb-10 pl-4 pr-8 bg-white border border-gray-200 xs:pb-6 md:px-6 md:py-6 lg:pl-10 lg:py-10 bg-opacity-10 rounded-xl border-opacity-10">
        <h3 class="w-full text-2xl text-white lg:pr-8 md:pr-4 lg:text-3xl lg:w-auto">
          We're a top rated employer
        </h3>
        <div class="flex flex-wrap items-center mt-4 lg:mt-0">
          <svg-glassdoor class="h-8 mr-2.5" />
          <div class="flex items-center mt-2.5 xs:mt-0 w-full xs:w-auto">
            <div class="mr-4 text-base text-white lg:text-3xl">
              {{ number }}
            </div>
            <div class="relative">
              <div class="absolute top-0 left-0 z-10 space-x-2.5 flex overflow-hidden">
                <div v-for="n in Math.ceil(number)"
                     :key="n"
                     class="flex-shrink-0"
                >
                  <div class="overflow-hidden"
                       :style="{ width: calculateWidth(n) }"
                  >
                    <svg-glassdoor-star class="h-6 lg:h-8" />
                  </div>
                </div>
              </div>
              <div class="relative space-x-2.5 flex">
                <svg-glassdoor-star v-for="n in 5"
                                    :key="n"
                                    class="h-6 lg:h-8 grayscale"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { CtaType } from '../../types/button'

interface glassdoorProps {
  heading: {
    type: 'primary' | 'secondary'
    title: string
    cta: CtaType
  }
  subtitle: string
}
defineProps<glassdoorProps>()

// TODO get the number from glassdor api
const number = ref(4.4)

const calculateWidth = (n: number) => {
  return Math.floor(number.value) === n - 1 ? `${(number.value - Math.floor(number.value)) * 100}%` : 'auto'
}
</script>
